import React from "react";
import Router from "../../Routes/Router";

const LayOut = () => {
  return (
    <div>
      <Router />
    </div>
  );
};

export default LayOut;
