import React from "react";
import "./App.css";
import LayOut from "./Components/Layout/LayOut";

const App = () => {
  return (
    <div>
      <LayOut />
    </div>
  );
};

export default App;
